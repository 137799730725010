<template>
  <div v-loading="loading">
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/analysisPatrol' }">巡视点数据分析</el-breadcrumb-item>
        <el-breadcrumb-item>巡视点数据分析详情</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="form-box">
      <el-form :model="form" ref="ruleForm" label-width="100px">
        <div class="form-title">
          基本信息
          <a :href="'/statistics/wapi/v1/file/export/patrol/report?patrolId='+form.patrolId" target="_blank"><el-button style="margin-left: 10px;float:right" type="primary" size="mini">导出报告</el-button></a>
        </div>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="巡视点名称：">{{ form.patrolName }}</el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="巡视点代号：">{{ form.patrolId }}</el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="位置名称：">{{ form.facilityName }}</el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="设备数量：">{{ form.patrolDeviceCount }}</el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="正常累计：">{{ form.normalCount }}</el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="异常累计：">{{ form.abnormalCount }}</el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="漏检累计：">{{ form.missCount }}</el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="维修累计：">{{ form.repairCount }}</el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="巡视次数：">{{ form.patrolTaskCount }}</el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="报废累计：">{{ form.scrapCount }}</el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="form-box">
      <div class="form-title">设备巡视信息</div>
      <el-table :data="listData" style="width: 100%">
        <el-table-column label="序号" type="index" width="50"></el-table-column>
        <el-table-column prop="alias" label="设备别名"></el-table-column>
        <el-table-column prop="deviceName" label="设备名称"></el-table-column>
        <el-table-column prop="model" label="型号"></el-table-column>
        <el-table-column prop="typeName" label="设备类型"></el-table-column>
        
        <el-table-column prop="repairCount" label="维修累计"></el-table-column>
        <el-table-column prop="normalCount" label="正常累计"></el-table-column>
        <el-table-column prop="abnormalCount" label="异常累计"></el-table-column>
        <el-table-column prop="failureRate" label="故障率"></el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {},
      row: {},
      listData: [],
      loading: false
    };
  },
  methods: {
    loadData() {
      this.loading = true
      this.$ajax.post("analysisDetail", {
        patrolId: this.form.patrolId,
      }).then((res) => {
        this.listData = res.data;
        this.loading = false
      }).catch(err=>{
        this.loading = false
      })
    },
  },
  mounted() {
    if (this.$route.params.row == null) {
			this.$router.push('/analysisPatrol')
		} else {
      this.form = this.$route.params.row
    }
    this.loadData()
  },
};
</script>

<style lang="less" scoped>
.img-box {
  img {
    display: inline-block;
    width: 200px;
    height: 200px;
    margin-left: 10px;
  }
}
</style>
<style lang="less">
</style>
